@font-face {
  font-family: 'Brown';
  font-display: swap;
  font-weight: 700;
  font-style: normal;
  src: url('./../node_modules/@robinpowered/design-system/dist/fonts/BrownLLWeb-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Brown';
  font-display: swap;
  font-weight: 500;
  font-style: normal;
  src: url('./../node_modules/@robinpowered/design-system/dist/fonts/BrownLLWeb-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Brown';
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: url('./../node_modules/@robinpowered/design-system/dist/fonts/BrownLLWeb-Regular.woff2') format('woff2');
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Brown', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI',
    'Roboto', 'Helvetica', 'Arial', 'sans-serif', 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}
